* {
  box-sizing: border-box;
  margin: 0;
  padding: 0;
}

body {
  height: 100vh;
  font-family: 'Poppins', sans-serif;
  background: #fff !important;
  overflow-x: hidden;
  z-index: 1;
}